import tw, { css, styled } from "twin.macro"

import { StyledLink } from "../../../Styled/Button"
import { P, Small } from "../../../Styled/Text"

export const Overlay = styled.section`
  ${tw`fixed inset-0 flex items-center justify-center z-30 invisible opacity-0 bg-white bg-opacity-70 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
`
export const Popup = styled.div`
  ${tw`relative w-full bg-white rounded-lg shadow overflow-x-hidden overflow-y-auto max-h-full invisible transform scale-90 translate-y-6 transition-all duration-200`}
  ${({ active }) => active && tw`visible scale-100 translate-y-0`}
`
export const Inner = tw.div`flex items-center justify-center w-full h-full p-6 w-full max-w-screen-lg`
export const Content = styled.div`
  ${tw`flex flex-col justify-between h-full w-full p-6 md:p-8`}

  ${({ height }) =>
    height &&
    css`
      @media (min-width: 768px) {
        max-height: ${height}px;
      }
    `}

  &::-webkit-scrollbar {
    ${tw`w-1`}
  }
  &::-webkit-scrollbar-thumb {
    ${tw`bg-grey-light rounded-3xl`}
  }
`
export const Pin = styled.div`
  ${tw`absolute top-0 left-0 w-7.5 h-7.5`}
  ${({ height, left, top, width }) =>
    height &&
    width &&
    css`
      left: ${(width / 100) * left}px;
      top: ${(height / 100) * top}px;
    `}
`
export const Circle = styled.button`
  ${tw`absolute top-0 w-full h-full flex items-center justify-center rounded-full bg-black bg-opacity-75 hover:bg-grey-darkest transition-colors duration-200 transform -translate-x-1/2 -translate-y-1/2 focus:outline-none`}
  ${({ active }) => active && tw`bg-grey-darkest`}
`
export const Popover = styled.div`
  ${tw`absolute top-full mt-3 md:mt-5 whitespace-nowrap invisible opacity-0 transform -translate-y-1/2 -translate-x-1/2 scale-90 transition-all duration-100`}
  ${({ active }) => active && tw`visible opacity-100 scale-100`}
  ${({ top }) => top && tw`-top-full -mt-3 md:-mt-5`}
  ${({ left }) => left && tw`top-0 right-0 left-auto mt-0 md:mt-0 mr-13 translate-x-0`}
  ${({ right }) => right && tw`top-0 left-0 right-auto mt-0 md:mt-0 ml-6 translate-x-0`}
`
export const Link = tw(
  StyledLink
)`justify-between px-3 md:pb-3 py-2.5 rounded-md bg-black bg-opacity-75 hover:text-white hover:opacity-100 hover:bg-grey-darkest transition-colors duration-200`
export const LinkText = tw.div`block pr-3 flex-grow text-left`
export const LinkType = tw(Small)`hidden md:block`
export const LinkIcon = tw.div`flex-shrink-0 mr-3`
export const Image = tw.div`relative`
export const Navigation = tw.div`w-full flex-shrink-0`
export const Text = tw(P)`w-full flex-grow md:overflow-x-hidden md:overflow-y-auto`
export const Button = tw(StyledLink)`block w-5`
export const Close = tw(Button)`absolute md:static top-5 right-5 md:top-auto md:right-auto`
